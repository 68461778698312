import { getTimelineData, startProcess, checkProcess } from "@/data"

export type Timeline = {
  // owner: string
  // isDailyEnabled: boolean
  // isWeeklyEnabled: boolean
  // dailyTime: string
  // dailyTimeServer: string
  // weeklyTimeServer: string
  // weeklyTime: string
  // weeklyDay: string
  // timeZone: string
}
export type TimelineState = {
  timeline: []
}
export default {
  namespaced: true,

  state: () => ({
    timeline: []
  }),
  mutations: {
    setTimeline(state: TimelineState, timeline: any) {
      state.timeline = timeline
    }
  },
  actions: {
    async getTimelineData({ rootState, commit }: any) {
      try {
        const { token, userIdForDebugging } = rootState
        let { userId } = rootState
        if (userIdForDebugging !== 0) {
          userId = userIdForDebugging
        }
        const response = await getTimelineData(token, userId)
        commit("setTimeline", response.data)
      } catch (e) {
        console.log(e.name)
      }
    },
    async startProcess({ rootState }: any) {
      try {
        const { token, userIdForDebugging } = rootState
        let { userId } = rootState
        if (userIdForDebugging !== 0) {
          userId = userIdForDebugging
        }

        return await startProcess(token, userId)
      } catch (e) {
        console.log(e.name)
      }
    },
    async checkProcess({ rootState }: any) {
      try {
        const { token, userIdForDebugging } = rootState
        let { userId } = rootState
        if (userIdForDebugging !== 0) {
          userId = userIdForDebugging
        }

        return checkProcess(token, userId)
      } catch (e) {
        console.log(e.name)
      }
    }
  }
}
