<template>
  <section v-if="isLoaded">
    <h1 v-if="!fromDCS" class="preferences-title">Coach Jack Notifications in Telegram.</h1>
    <div class="cj-card cj-into-card fields-container" :class="{ center: fromDCS }">
      <b>Daily notifications</b>
      <toggle v-model="isDailyEnabled" />
      <select-time :is-admin="isAdmin" :isDisabled="!isDailyEnabled" :value="dailyTime" @change="changeDailyTime" />
      <div class="tomorrow_notification" v-if="isTomorrow">
        After 6 pm you'll see tomorrows workouts
      </div>
      <div v-else class="tomorrow_notification">Before 6 pm you will see today's workouts</div>
      <b>Weekly notifications</b>
      <toggle v-model="isWeeklyEnabled" />
      <select-day :isDisabled="!isWeeklyEnabled" :value="weeklyDay" @change="changeDay" />
      <select-time :is-admin="isAdmin" :isDisabled="!isWeeklyEnabled" :value="weeklyTime" @change="changeWeeklyTime" />
    </div>
    <div v-if="!fromDCS" class="footer cj-card cj-into-card pt-0">
      <div class="buttons">
        <TelegramConnect />
        <button @click="save" class="button is-green">Save</button>
      </div>
      <div class="mt-5">
        <router-link :to="{ name: 'history' }">Back to Plan History</router-link>
      </div>
    </div>
    <div v-else-if="fromDCS">
      <div class="button-save">
        <button @click="save" class="button is-green is-full-width">Save</button>
      </div>
    </div>
  </section>
  <section v-else-if="fromDCS" class="loader-container">
    <img class="page-loader-icon" src="@/assets/images/loader.gif" alt="Loader" />
  </section>
</template>

<script>
import Toggle from "@/components/Toggle"
import SelectTime from "@/components/SelectTime"
import SelectDay from "@/components/SelectDay"
import { mapActions, mapGetters, mapState } from "vuex"
import TelegramConnect from "@/components/preferences/TelegramConnect.vue"
import moment from "moment"

export default {
  name: "preferences",
  data() {
    return {
      isLoaded: false,
      isDailyEnabled: false,
      isWeeklyEnabled: false,
      dailyTime: "",
      weeklyTime: "",
      weeklyDay: "",
      timeZone: ""
    }
  },
  props: {
    fromDCS: { type: Boolean, default: false }
  },
  computed: {
    ...mapState("preferences", ["preferences"]),
    ...mapGetters(["isAdmin"]),
    isTomorrow() {
      const timeInHours = this.parseHours(this.dailyTime)
      const sixPmInHours = 18
      return timeInHours > sixPmInHours
    }
  },
  components: {
    TelegramConnect,
    Toggle,
    SelectTime,
    SelectDay
  },
  async mounted() {
    await this.getPreferences()

    this.isDailyEnabled = this.preferences.isDailyEnabled
    this.isWeeklyEnabled = this.preferences.isWeeklyEnabled
    this.dailyTime = this.preferences.dailyTime
    this.weeklyTime = this.preferences.weeklyTime
    this.weeklyDay = this.preferences.weeklyDay
    if (!this.preferences.owner || !this.preferences.trainerDayUserId) {
      await this.save()
    }
    this.isLoaded = true
  },

  methods: {
    ...mapActions("preferences", ["savePreferences", "getPreferences"]),

    async save() {
      this.isLoaded = false
      const payload = {
        isDailyEnabled: this.isDailyEnabled,
        isWeeklyEnabled: this.isWeeklyEnabled,
        dailyTime: this.dailyTime,
        weeklyTime: this.weeklyTime,
        weeklyDay: this.weeklyDay,
        timeZone: this.timeZone,
        clientDateDaily: moment(this.dailyTime, "HH:mm").format("YYYY-MM-DD HH:mm:ss"),
        clientDateWeekly: moment(this.weeklyTime, "HH:mm")
          .day(this.weeklyDay)
          .format("YYYY-MM-DD HH:mm:ss"),
        fromDCS: this.fromDCS
      }
      await this.savePreferences(payload)
      this.isLoaded = true
      if (this.fromDCS) {
        this.$emit("close")
      }
    },
    changeDailyTime(time) {
      this.dailyTime = time
    },
    changeWeeklyTime(time) {
      this.weeklyTime = time
    },
    changeDay(day) {
      this.weeklyDay = day
    },
    parseHours(time) {
      const { hours, minutes } = this.parseTime(time)
      const minutesInHour = 60
      const hoursFromMinutes = minutes / minutesInHour

      const result = (hours + hoursFromMinutes).toFixed(2)

      return Number(result)
    },
    parseTime(time) {
      const [, sign, hours, minutes] = time.match(/([+-]?)(\d+):(\d+)/)

      return {
        sign,
        hours: Number(hours),
        minutes: Number(minutes)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.cj-into-card {
  margin: 0;
}
.tomorrow_notification {
  font-size: 10px;
}
.preferences-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 40px;
  position: relative;
  z-index: 1;
  padding-left: 40px;
  padding-top: 20px;
  @include media("<=tablet") {
    padding: 16px;
  }
}
.fields-container {
  display: grid;
  grid-template-columns: auto auto 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 16px;
  align-items: center;
  grid-template-areas:
    ". . . ."
    ". . . ."
    ". timezone timezone timezone";
  @include media("<=tablet") {
    padding: 16px;
  }
}
.center {
  margin: auto;
}
.timezone {
  grid-area: timezone;
}
.loader-container {
  height: 299px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons {
  display: grid;
  grid-template-columns: 1.25fr 0.75fr;
  gap: 32px;
  @include media("<=tablet") {
    grid-template-columns: auto 0.75fr;
  }
}
.footer {
  @include media("<=tablet") {
    padding: 16px;
  }
}
.button-save {
  width: 350px;
  margin: auto auto 40px;
}
</style>
