<template>
  <main class="dashboard-wrapper">
    <iframe
      class="dashboard-iframe"
      id="dashboard-iframe"
      @load="setLoading(false)"
      :src="iframeDashboard"
      allowfullscreen
      allow="fullscreen"
      border="0"
    ></iframe>
    <DCSPreferencesModal v-if="showPreferenceModal" @close="setShowPreferencesModal(false)" />
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import DCSPreferencesModal from "@/components/dcs/DcsPreferencesModal.vue"
import configuration from "@/configuration"
import { ROUTE_NAMES } from "@/defaults"

export default {
  name: "Videos",
  components: { DCSPreferencesModal },
  async beforeMount() {
    this.setLoading(true)
    window.addEventListener("message", this.receiveMessage, false)
  },
  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage)
  },
  receiveMessage(message) {
    const { type } = message.data
    if (type === "go-to-history-page") {
      const path = "/history"
      if (this.$route.path !== path) this.$router.push({ name: "history" })
    }
    if (type === "go-to-dcs-page") {
      const path = "/"
      if (this.$route.path !== path) this.$router.push({ name: "dcs" })
    }
    if (type === "create-plan") {
      this.$router.push({ name: ROUTE_NAMES.WELCOME_DCS })
    }
  },
  computed: {
    ...mapGetters("preferences", ["owner", "isConnected"]),
    ...mapGetters(["isShowPreferencesModal"]),
    iframeDashboard() {
      return (
        `${configuration.webAppUrl}/dcs-dashboard?isConnected=` + this.isConnected + "&trainerDayObjectId=" + this.owner
      )
    },
    showPreferenceModal() {
      return this.isShowPreferencesModal
    }
  },
  methods: {
    ...mapMutations(["setLoading", "setShowPreferencesModal"]),
    ...mapActions("preferences", ["getPreferences"])
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  &-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  &-iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
