<template>
  <section class="container">
    <div class="cj-card cj-into-card text-center">
      <div class="cj-blue-round mb-16">
        <img src="@/assets/images/jack-full.svg" alt="Coach Jack" />
      </div>
      <div class="text-left">
        <h3 class="cj-says">{{ $t("stepName.success") }}</h3>
        <p class="cj-desc mb-32">
          {{ $t("stepName.myName") }}
        </p>
      </div>

      <div class="field text-left mb-24">
        <label class="label">{{ $t("stepName.name") }}</label>
        <div class="control">
          <input v-model="name" class="input" type="text" ref="name" @keydown.enter="next" />
        </div>
      </div>
      <div class="field text-left mb-24">
        <label class="label">{{ $t("stepName.ftp") }} *</label>
        <div class="control">
          <input v-model="userFtp" class="input" type="text" ref="ftp" @keydown.enter="next" />
        </div>
        <div style="font-size:13px;color:#909090; margin-top:10px">
          * {{ $t("stepName.note") }}
          <a href="https://app.trainerday.com/workouts/trainerday-ramp-test" target="_blank">{{
            $t("stepName.here")
          }}</a
          >.
        </div>
      </div>

      <button @click="next" class="button-220 button is-red">
        {{ $t("stepName.start") }}
      </button>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { CONFIRM_NAME_AND_FTP } from "@/services/eventNames"
import { ROUTE_NAMES } from "@/defaults"

export default {
  name: "StepName",
  data() {
    return {
      name: "",
      userFtp: 0
    }
  },
  computed: {
    ...mapState(["profileFacts", "externalFtp"])
  },
  beforeMount() {
    this.name = this.profileFacts.firstName
    this.userFtp = this.profileFacts.ftp || this.externalFtp
  },
  mounted() {
    this.focusInput()
  },
  methods: {
    async next() {
      await this.$store.dispatch("upsertUser", { profileFacts: { firstName: this.name, ftp: this.userFtp } })
      await this.$trackEvent({ name: CONFIRM_NAME_AND_FTP })
      await this.$router.push({ name: ROUTE_NAMES.QUESTIONING })
    },
    focusInput() {
      this.$refs.name.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.container {
  padding: $containerPadding;
}
.field {
  width: 100%;
}
</style>
