import { mapState } from "vuex"
import configuration from "@/configuration"
const { appUrl } = configuration

export default {
  computed: {
    ...mapState(["isEmbedded"])
  },
  methods: {
    error({ type, message }: { type?: string; message?: string }) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (this.isEmbedded) {
        if (type) {
          window.parent.postMessage({ type }, appUrl)
        }
      } else {
        if (message) {
          alert(message)
        }
      }
    }
  }
}
