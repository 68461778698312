import Vue from "vue"
import Vuex from "vuex"
import { i18n } from "@/main"
import to from "await-to-js"
import jwtDecode from "jwt-decode"
import createPersistedState from "vuex-persistedstate"

import {
  getProfile,
  upsertUser,
  fillDefaultFacts,
  resetProcess,
  checkPremium,
  pullStravaFacts,
  fillProfileFacts,
  upsertProfileByToken,
  getTranslation
} from "@/data"
import assessment from "./assessment"
import plan from "./plan"
import history from "./history"
import preferences from "./preferences"
import timeline from "./timeline"
import student from "./student"
import processRules from "./services/processRules"
import moment from "moment"
import { MIN_WEEKS_DURATION } from "@/defaults"
import VueCookies from "vue-cookies"
import { AxiosError } from "axios"
import { getProfileFactsRules } from "@/store/services/getProfileFactsRules"
import { setDayjsLocale } from "@/store/services/setDayjsLocale"

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueCookies)

export type CustomPlan = {
  name: string
}

export interface Block {
  blockType: string
  planType: string
  workoutDays: Array<string>
  bestLongRideDays: Array<string>
  customPlan: CustomPlan
  currentIntervalIntensityLevel: number
  weeklyHoursMin: number
  isMinWeeklyMinutesAuto: boolean
  weeklyHoursMax: number
  rampRate: number
  secondaryIntensity: number
  secondaryType: string
  splitLongRides: boolean
  minutesPerDay: any
  recoveryPattern: string
  isFixedHours: boolean
  isFixedHoursAuto?: boolean // only for EVENT block
  isEndHoursRecommended: boolean
}

export interface ProfileFacts {
  isJackRecommendation: boolean
  age: string | null
  firstName: string
  focusAreas: Array<any>
  ftp: number | null
  units: string
  weight: number
  periodizationType: string
  hrv: Array<object>
  jackLastAssessmentDate?: Date
  dcsLastOnboardingDate?: Date
}

export type State = {
  userIdForDebugging: number
  isQuestionsSkip: boolean
  externalFtp: number
  _id: string | null
  token: string | null
  accessLevel: number
  userId: number
  roles: any[]
  isEmbedded: boolean
  action: string | null
  isLoading: boolean
  isMobile: boolean
  stravaAuthorized: undefined | boolean
  initialFacts: any
  stravaFacts: any
  weeks: number
  profileFacts: ProfileFacts
  locale: string
  isWrongDates: boolean
  showPreferencesModal: boolean
}

interface TokenPayload {
  roles?: string[]
  accessLevel?: number
  username?: string
  userId?: number
}

const persistedState = createPersistedState({
  paths: ["_id", "assessment.isSkippingStrava", "plan.activeBlock"]
})

const action: null | string = null

const token = Vue.$cookies.get("token")

let tokenPayload: TokenPayload

try {
  tokenPayload = jwtDecode(token)
} catch (e) {
  tokenPayload = {}
}

const { roles = [], accessLevel = 0, username = "", userId = 0 } = tokenPayload

const sentryUser = {
  id: userId,
  username,
  accessLevel,
  roles: roles.join(", ")
}
export { sentryUser }

const Store = new Vuex.Store({
  modules: {
    plan,
    assessment,
    history,
    preferences,
    timeline,
    student
  },
  getters: {
    isPremiumUser: state => state.accessLevel >= 2,
    isTrainerDayUser: state => !!state.token,
    isStravaAuthorized: state => state.stravaAuthorized,
    isMobile: state => state.isMobile,
    isShowPreferencesModal: state => state.showPreferencesModal,
    isQuestionsSkip: state => state.isQuestionsSkip,
    getFirstName: state => state.profileFacts?.firstName ?? "",
    isJackAssessmentCompletedOnceAtLeast: state => Boolean(state.profileFacts?.jackLastAssessmentDate),
    isDcsOnboardingCompletedOnceAtLeast: state => Boolean(state.profileFacts?.dcsLastOnboardingDate),
    getProfileFactsValue: state => (name: keyof ProfileFacts): ProfileFacts[keyof ProfileFacts] =>
      getProfileFactsRules(state.profileFacts, name),
    difficultyLevel: () => 0,
    isDevelopment: state => !state.isEmbedded,
    isBetaUser: state =>
      state.roles
        ? state.roles.filter(e => e.includes("beta")).length > 0 || state.roles.includes("administrator")
        : false,
    isAdmin: state => (state.roles ? state.roles.includes("administrator") : false),
    getUserIdForDebugging: state => state.userIdForDebugging,
    getAction: state => state.action
  },
  state: {
    userIdForDebugging: 0,
    externalFtp: 0,
    showPreferencesModal: false,
    isQuestionsSkip: false,
    _id: null,
    token,
    action,
    accessLevel,
    userId,
    roles,
    isEmbedded: false,
    isLoading: false,
    isMobile: false,
    stravaAuthorized: undefined,
    initialFacts: {},
    stravaFacts: {},
    weeks: MIN_WEEKS_DURATION,
    profileFacts: {
      isJackRecommendation: false,
      age: null,
      firstName: "",
      focusAreas: [],
      ftp: null,
      units: "",
      weight: 0,
      periodizationType: "",
      hrv: [],
      jackLastAssessmentDate: undefined,
      dcsLastOnboardingDate: undefined
    },
    locale: localStorage.lang || "en",
    isWrongDates: false
  },
  mutations: {
    changeWeeksDuration(state: State, weeks: number) {
      state.weeks = weeks
    },
    setProfileFactsValue<Name extends keyof ProfileFacts>(
      state: State,
      { name, value }: { name: Name; value: ProfileFacts[Name] }
    ) {
      state.profileFacts[name] = value
    },
    setBlockSettingsValue<Name extends keyof Block>(
      state: State,
      { block, name, value }: { block: Block; name: Name; value: Block[Name] }
    ) {
      Vue.set(block, name, value)
      processRules(block, name)
    },
    setLoading(state: State, isLoading: boolean) {
      state.isLoading = isLoading
    },
    setShowPreferencesModal(state: State, value: boolean) {
      state.showPreferencesModal = value
    },
    setStravaFacts(state: State, stravaFacts: object) {
      state.stravaFacts = stravaFacts
    },
    setSkipStrava(state: any, isSkipping) {
      state.assessment.isSkippingStrava = isSkipping
    },
    setStravaAuthorized(state: State, isAuthorized: boolean) {
      state.stravaAuthorized = isAuthorized
    },
    setAction(state: State, action: string) {
      state.action = action
    },
    setEmbedded(state: State, isEmbedded: boolean) {
      state.isEmbedded = isEmbedded
    },
    setFtp(state: State, ftp: number) {
      state.externalFtp = ftp
    },
    setUserIdForDebugging(state: State, userId: number) {
      state.userIdForDebugging = userId
    },
    setToken(state: State, token: string) {
      interface DecodedToken {
        accessLevel?: number
        userId: number
        roles?: string[]
      }

      const { accessLevel = 0, userId, roles = [] } = jwtDecode(token) as DecodedToken

      state.token = token
      state.accessLevel = accessLevel
      state.userId = userId
      state.roles = roles
    },
    setInitialFacts(state: State, initialFacts: any) {
      state.initialFacts = initialFacts
    },
    resetFacts(state: State) {
      state.profileFacts = {
        ...state.profileFacts,
        ...state.initialFacts
      }
    },
    setUserObjectId(state: State, _id: string) {
      state._id = _id
    },
    setIsJackRecommendation(state: State, isJackRecommendation: boolean) {
      state.profileFacts = {
        ...state.profileFacts,
        isJackRecommendation
      }
    },
    setProfile(state: State, profile: any) {
      const { stravaFacts, profileFacts } = profile
      if (stravaFacts) {
        state.stravaFacts = {
          ...state.stravaFacts,
          ...stravaFacts
        }
      }

      // const isDevelopment = state.isEmbedded
      // const devArgs = isDevelopment ? [2, "minute"] : [1, "month"]
      const prodArgs = [1, "month"]

      if (profileFacts?.jackLastAssessmentDate) {
        state.isQuestionsSkip = moment(profileFacts.jackLastAssessmentDate)
          .add(...prodArgs)
          .isAfter(moment())
      } else {
        state.isQuestionsSkip = false
      }
      if (profileFacts) {
        state.profileFacts = {
          ...state.profileFacts,
          ...profileFacts
        }
        if (profileFacts.periodizationType) {
          state.profileFacts.periodizationType = profileFacts.periodizationType
        }
      }
    },
    setIsMobile(state: State, value: boolean) {
      state.isMobile = value
    },
    setProfileFacts(state: State, profileFacts: ProfileFacts) {
      state.profileFacts = profileFacts
    },
    setLocale(state: State, payload: string) {
      i18n.locale = payload
      state.locale = payload
      setDayjsLocale(payload)
      localStorage.setItem("lang", payload)
    },
    isWrongDates(state: State, payload: boolean) {
      state.isWrongDates = payload
    }
  },
  actions: {
    async getProfile({ commit, state }) {
      const { _id } = state

      if (!_id) return

      try {
        const response = await getProfile(_id)
        if ("data" in response) {
          commit("setUserObjectId", response.data._id)
          commit("setProfile", response.data)
        }
      } catch (e) {
        const error = e as AxiosError
        if (error.response && error.response.status === 404) {
          commit("setUserObjectId", null)
        }
      }
    },
    async upsertUser({ commit, state }, data) {
      const { _id, token } = state

      if (token) {
        data.token = token
      }

      if (!_id) {
        const response = await upsertUser(data)
        const { _id } = response.data
        commit("setUserObjectId", _id)
        commit("setProfile", response.data)
        return
      }

      const response = await upsertUser({ _id, ...data })
      commit("setProfile", response.data)
    },
    async upsertProfileByToken({ commit, state }) {
      const { token, userIdForDebugging: onBehalfUserId } = state

      const response = await upsertProfileByToken(token, onBehalfUserId)
      const { _id } = response.data
      commit("setUserObjectId", _id)
      commit("setProfile", response.data)
    },
    async fillProfileFacts({ commit, state }, { isDcs }) {
      commit("setLoading", true)
      commit("setIsJackRecommendation", true)
      try {
        const profileFactsRes = await fillProfileFacts(state._id, state.token, isDcs)
        const profileFacts = profileFactsRes.data
        commit("setProfile", { profileFacts })
        commit("setLoading", false)
      } catch (e) {
        commit("setLoading", false)
        throw e
      }
    },
    async fillDefaultData({ commit, state }, { isDcs }) {
      commit("setLoading", true)

      const { _id, token } = state
      const response = await fillDefaultFacts(_id, token, isDcs)
      commit("setProfileFacts", response.data)
      commit("setLoading", false)
    },
    async resetProcess({ state }) {
      const { _id } = state
      await resetProcess(_id)
    },
    async checkPremium({ commit, state }) {
      if (!state.token) throw new Error("Provide your token")

      commit("setLoading", true)
      try {
        await checkPremium(state.token)
        commit("setLoading", false)
      } catch (e) {
        commit("setLoading", false)
        throw e
      }
    },
    async pullStravaFacts({ commit, state }) {
      if (!state.token) throw new Error("Provide your token")
      commit("setLoading", true)
      const [err, profileRes] = await to(pullStravaFacts(state._id || null, state.token, state.userIdForDebugging))
      if (profileRes) {
        const { _id, firstName, ftp } = profileRes.data
        commit("setUserObjectId", _id)
        if (!state.profileFacts.firstName) {
          commit("setProfileFactsValue", { name: "firstName", value: firstName })
        }
        if (!state.profileFacts.ftp) {
          commit("setProfileFactsValue", { name: "ftp", value: ftp })
        }
      }
      commit("setLoading", false)

      if (err) {
        throw err
      }
    },
    async setLocale({ commit, dispatch }, payload) {
      const { lang, needBack } = payload
      commit("setLoading", true)
      commit("setLocale", lang)
      if (needBack) {
        await dispatch("plan/getValues")
      }
      commit("setLoading", false)
    },
    async getTranslation({ state, rootState }, payload) {
      const locale = state.locale
      const { token } = rootState
      const { text, model } = payload
      const [err, response] = await to(getTranslation(locale, text, model, token))
      if (err) {
        return
      }
      return response?.data[locale]
    }
  },
  plugins: [persistedState]
})

export default Store
