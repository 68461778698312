import { scaleLinear } from "d3-scale"
import { extent } from "d3-array"
import { area } from "d3-shape"
import { create } from "d3-selection"

export default {
  props: {
    segments: {
      type: Array,
      default: () => []
    },
    chartWidth: {
      type: Number,
      default: 0
    },
    chartHeight: {
      type: Number,
      default: 120
    },
    backgroundColor: {
      type: String,
      default: "#F9FAFE"
    }
  },
  data() {
    return {
      width: 0,
      height: 0,
      svg: null,
      linesG: null,
      segmentsPathEl: null,
      debouncedScale: () => ({})
    }
  },
  computed: {
    hasData() {
      return !!this.segments.length
    },
    segmentsData() {
      let timeAcc = 0

      return this.segments.length
        ? this.segments.reduce((acc, [time, start, end]) => {
            acc.push([timeAcc, start])
            timeAcc = timeAcc + time
            acc.push([timeAcc, end || start])
            return acc
          }, [])
        : []
    },
    x() {
      return scaleLinear()
        .domain(extent(this.segmentsData, ([time]) => time))
        .range([0, this.width])
    },

    y() {
      return scaleLinear()
        .domain([0, 200])
        .range([this.height, 0])
    },

    segmentsPath() {
      const { x, y } = this

      return area()
        .x(([time]) => x(time))
        .y0(y(0))
        .y1(([, value]) => y(value))(this.segmentsData)
    }
  },
  mounted() {
    this.width = this.chartWidth
    this.height = this.chartHeight

    this.init()
  },
  watch: {
    segmentsPath() {
      this.build()
    },
    chartWidth() {
      this.width = this.chartWidth
      this.svg.attr("width", this.chartWidth)
    },
    chartHeight() {
      this.height = this.chartHeight
      this.svg.attr("height", this.chartHeight)
    }
  },
  methods: {
    buildMainPath() {
      const { segmentsPath } = this

      if (!segmentsPath || !segmentsPath.length) return
      if (this.segmentsPathEl) this.segmentsPathEl.remove()

      this.segmentsPathEl = this.svg
        .append("path")
        .attr("d", segmentsPath)
        .attr("fill", "#5B8BE1")
    },
    buildLines() {
      const { width, height } = this

      if (this.linesG) this.linesG.remove()

      this.linesG = this.svg.append("g")

      this.linesG
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height)
        .attr("fill", this.backgroundColor)
      this.linesG
        .append("rect")
        .attr("x", 0)
        .attr("y", (height / 4) * 1)
        .attr("width", width)
        .attr("height", 0.7)
        .attr("fill", "#E2E3E3")
      this.linesG
        .append("rect")
        .attr("x", 0)
        .attr("y", (height / 4) * 2)
        .attr("width", width)
        .attr("height", 0.82)
        .attr("fill", "#FFCF74")
      this.linesG
        .append("rect")
        .attr("x", 0)
        .attr("y", (height / 4) * 3)
        .attr("width", width)
        .attr("height", 0.7)
        .attr("fill", "#E2E3E3")
    },
    init() {
      const { width, height } = this

      if (this.svg) this.svg.remove()

      this.svg = create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("style", "display: block;")

      this.build()

      this.$el.appendChild(this.svg.node())
    },
    build() {
      this.buildLines()
      this.buildMainPath()
    }
  }
}
