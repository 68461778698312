import moment, { Moment } from "moment"
import { DATE_FORMAT } from "@/types"

const format = (momentDate: Moment) => momentDate.format(DATE_FORMAT)

const getStartDate = (date: string) => {
  const nextMonday = moment(date)
    .startOf("isoWeek")
    .add(1, "week")

  const thisMonday = moment(date).startOf("isoWeek")
  const prevMonday = moment(nextMonday).subtract(1, "week")

  const isThisMonday = moment(date).isSame(thisMonday)
  const isDateBeforePrevMonday = moment(date).isBefore(prevMonday)
  const isDateAfterThisMonday = moment(date).isAfter(thisMonday)

  if (isDateBeforePrevMonday) {
    return format(prevMonday)
  }
  if (isThisMonday) {
    return format(thisMonday)
  }
  if (isDateAfterThisMonday) {
    return format(nextMonday)
  }
  return format(moment(date))
}

export default getStartDate
