import { QuestionType } from "@/types"
import { getNextQuestion, restoreQuestionsAndAnswers, upsertUser } from "@/data"

export type AssessmentState = {
  isSkippingStrava: boolean
  questionCursor: string
  questions: QuestionType[]
  assessmentFacts: object
  month: []
}

const transformData = (
  state: { questions: QuestionType[]; questionCursor: string; isSkippingStrava: boolean },
  questionKey: string,
  textAnswer: string
) => {
  const { questions, questionCursor, isSkippingStrava } = state

  const data: any = { lastEvent: questionCursor }

  questions.forEach((item: QuestionType) => {
    data[item.key] = item.userChoice
    if (item.key === questionKey) data[item.key] = textAnswer // hack missing answer
  })

  data["skip_strava"] = Number(isSkippingStrava)

  return data
}

const getEarliestQuestionKey = (questions: QuestionType[], dependentQuestions: string[]) => {
  const questionsKeys = questions.map(q => q.key)

  const existedDependentQuestions = dependentQuestions.filter(q => q)

  const sortedDependentQuestions = [...existedDependentQuestions].sort(function(a, b) {
    return questionsKeys.indexOf(a) - questionsKeys.indexOf(b)
  })

  const firstQuestionToCut = sortedDependentQuestions.shift()

  const firstQuestionToCutIndex = questions.findIndex(q => q.key === firstQuestionToCut)

  if (firstQuestionToCutIndex >= 0) {
    return questions[firstQuestionToCutIndex].key
  }

  return null
}

export default {
  namespaced: true,
  state: () => ({
    isSkippingStrava: false,
    questionCursor: "",
    questions: [],
    assessmentFacts: {}
  }),
  getters: {
    isAssessmentCompleted: (state: AssessmentState) =>
      state.questions.length > 0
        ? !state.questions
            .filter(q => q.key !== "final_question") // TODO: is there a better way?
            .find(q => {
              return !q.isAnswered || (q.isAnswered && q.type && !q.userChoice)
            })
        : false,
    getAssessmentFactsValue: (state: AssessmentState) => (name: keyof {}) => state.assessmentFacts[name],
    isFinalQuestion: (state: AssessmentState) =>
      state.questions.length && state.questions[state.questions.length - 1].key === "final_question",
    getFinalQuestionText: (state: AssessmentState) =>
      state.questions.length && state.questions[state.questions.length - 1].key === "final_question"
        ? state.questions[state.questions.length - 1].text
        : null,
    getFirstMonthPlan: (state: AssessmentState) => state.month,
    completePercent: (state: AssessmentState) => {
      const currentQuestion = state.questions.length && state.questions.find(q => q.key === state.questionCursor)

      if (currentQuestion) {
        return currentQuestion.totalProgress
      }
      return 0
    },
    getQuestionByKey: (state: AssessmentState) => (questionKey: string): QuestionType | undefined =>
      state.questions.find(q => q.key === questionKey),
    getPreviousQuestionKey: (state: AssessmentState) => (questionKey: string): QuestionType | undefined => {
      const finalIndex = state.questions.findIndex(q => q.key === questionKey)

      return state.questions[finalIndex - 1]
    },
    getTransformedData: (state: AssessmentState) => (questionKey: string, textAnswer: string) =>
      transformData(state, questionKey, textAnswer),
    getEarliestQuestionKey: (state: AssessmentState) => (dependentQuestions: string[]) =>
      getEarliestQuestionKey(state.questions, dependentQuestions)
  },
  mutations: {
    appendQuestionIfNotExist(state: AssessmentState, question: QuestionType) {
      if (question.key === "final_question") {
        question.isVisible = false
        state.questionCursor = "final_question"

        state.questions.push(question)

        return
      }

      const { questionCursor: appendAfter } = state

      const isQuestionExist = state.questions.findIndex(q => q.key === question.key) !== -1

      if (isQuestionExist) return

      if (appendAfter) {
        const { skipped = 0 } = question

        const index = state.questions.findIndex(q => q.key === appendAfter)
        state.questions.splice(index + skipped + 1, 0, question)
      } else {
        state.questions.push(question)
      }
    },
    dropQuestions(state: AssessmentState, questionsKeysToDrop: string[]) {
      state.questions = state.questions.filter(q => !questionsKeysToDrop.includes(q.key))
    },
    setQuestionCursor(state: AssessmentState, questionCursor: string) {
      state.questionCursor = questionCursor
    },
    cutFinalQuestion(state: AssessmentState) {
      const index = state.questions.findIndex(q => q.key === "final_question")
      if (index !== -1) {
        state.questions.splice(index, 1)
      }
    },
    setAssessmentFacts(state: AssessmentState, assessmentFacts: {}) {
      state.assessmentFacts = assessmentFacts
    },
    cutDependentQuestions(state: AssessmentState, dependentQuestions: string[]) {
      dependentQuestions.forEach(questionKey => {
        const index = state.questions.findIndex(sq => sq.key === questionKey)
        if (index !== -1) {
          state.questions.splice(index, 1)
        }
      })
    },
    editAnswer(state: AssessmentState, questionKey: string) {
      const question = state.questions.find(q => q.key === questionKey)
      if (!question) return
      question.isAnswered = false
    },
    flushQuestions(state: AssessmentState) {
      state.questionCursor = ""
      state.questions = []
    },
    answerQuestion(state: AssessmentState, { questionKey, textAnswer }: { questionKey: string; textAnswer: string }) {
      const question = state.questions.find(q => q.key === questionKey)
      if (!question) return
      question.userChoice = textAnswer
      question.isAnswered = true
    },
    setRestoredQuestions(state: AssessmentState, questions: QuestionType[]) {
      state.questions = questions.map((q: QuestionType) => {
        return {
          ...q,

          isVisible: true,
          isAnswered: true
        }
      })
    },
    setRestoredFacts(state: AssessmentState, facts: QuestionType[]) {
      state.assessmentFacts = facts
    },
    setOnly4Weeks(state: AssessmentState, data: []) {
      state.month = data
    }
  },
  actions: {
    async restoreQuestionsAndAnswers({
      rootState,
      commit,
      state
    }: {
      rootState: any
      commit: Function
      state: AssessmentState
    }) {
      const { isSkippingStrava } = state
      const { _id } = rootState

      const res = await restoreQuestionsAndAnswers(_id, Number(isSkippingStrava))

      if (res.data && res.data.questions.length) {
        commit("setRestoredQuestions", res.data.questions)
      }

      if (res.data && res.data.facts) {
        commit("setRestoredFacts", res.data.facts)
      }
    },
    async saveAnswer(
      { commit, dispatch, getters, rootState }: { getters: any; commit: Function; dispatch: Function; rootState: any },
      { questionKey, textAnswer }: { questionKey: string; textAnswer: string }
    ) {
      const { getQuestionByKey, getPreviousQuestionKey, getTransformedData, getEarliestQuestionKey } = getters

      if (!questionKey) {
        // Getting the first question
        dispatch("postAnswerAndGetNewQuestion", { questionKey: "", textAnswer: "" })
        return
      }

      let question

      if (questionKey !== "final_question") {
        question = getQuestionByKey(questionKey)
      } else {
        question = getPreviousQuestionKey("final_question")

        questionKey = question.key
        textAnswer = question.userChoice
      }

      const { userChoice, dependentQuestions } = question

      const { _id } = rootState

      const isUserChangedHisMind = !!userChoice && userChoice !== textAnswer
      const isDependentQuestionsExist = dependentQuestions && dependentQuestions.length

      commit("answerQuestion", { questionKey, textAnswer })

      if (isUserChangedHisMind && isDependentQuestionsExist) {
        commit("cutDependentQuestions", dependentQuestions)
        const earliestKey = getEarliestQuestionKey(dependentQuestions)
        commit("cutDependentQuestions", dependentQuestions)
        commit("setQuestionCursor", earliestKey || questionKey)
        commit("cutFinalQuestion")
        dispatch("postAnswerAndGetNewQuestion", { questionKey, textAnswer })
      } else {
        commit("setQuestionCursor", questionKey)
        commit("cutFinalQuestion")
        dispatch("postAnswerAndGetNewQuestion", { questionKey, textAnswer })
      }

      if (_id) {
        const assessmentFacts = getTransformedData(questionKey, textAnswer)

        commit("setAssessmentFacts", assessmentFacts)
        await upsertUser({ _id, assessmentFacts })
      }
    },
    async postAnswerAndGetNewQuestion(
      { getters, commit }: { getters: any; commit: Function },
      { questionKey, textAnswer }: { questionKey: string; textAnswer: string }
    ) {
      const { getTransformedData, getEarliestQuestionKey, getPreviousQuestionKey } = getters
      const data = getTransformedData(questionKey, textAnswer)

      if (data.lastEvent && !data[data.lastEvent] && questionKey !== "final_question") {
        commit("editAnswer", data.lastEvent)
        return
      }

      const response = await getNextQuestion(data)

      const nextQuestion: QuestionType = response.data

      if (!nextQuestion) return

      if (nextQuestion.dropQuestions && nextQuestion.dropQuestions.length) {
        commit("dropQuestions", nextQuestion.dropQuestions)

        const key = getEarliestQuestionKey(nextQuestion.dropQuestions)
        const prevKey = getPreviousQuestionKey(key)
        commit("setQuestionCursor", prevKey || questionKey)
        delete nextQuestion.dropQuestions
      }

      nextQuestion.isAnswered = false
      nextQuestion.isVisible = true

      if (nextQuestion.answers) {
        nextQuestion.answers.forEach(answer => {
          answer.class = ""
        })
      }

      commit("appendQuestionIfNotExist", nextQuestion)
    }
  }
}
