<template>
  <section class="container coach-jack">
    <div class="cj-card cj-into-card text-center">
      <div class="cj-images">
        <div class="robert-black-round">
          <img src="@/assets/images/robert-full.png" alt="Coach Robert" />
        </div>
        <div class="logo">
          <div class="logo-td">
            <img src="@/assets/images/td_white_text.svg" alt="Trainer Day logo" />
          </div>
          <div>
            <img src="@/assets/images/dcs.svg" alt="DCS" />
          </div>
          <p class="logo-dcs">{{ $t("stepWelcome.dcsText") }}</p>
        </div>

        <div class="cj-blue-round mb-16">
          <img src="@/assets/images/jack-full.svg" alt="Coach Jack" />
        </div>
      </div>
      <div class="has-text-centered hello-text">
        <h3 class="cj-says ">{{ $t("stepWelcome.helloDCS") }}</h3>
      </div>

      <button @click="connect" class="button strava-button">
        {{ isStravaAuthorized ? $t("stepWelcome.analyze") : $t("stepWelcome.connect") }}
        <img src="@/assets/images/strava-logo.svg" alt="Strava Logo" />
      </button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import to from "await-to-js"
import error from "@/mixins/error"
import { ROUTE_NAMES } from "@/defaults"

export default {
  name: "StepWelcomeDCS",
  mixins: [error],
  mounted() {
    this.setLoading(false)
  },
  computed: {
    ...mapGetters(["isTrainerDayUser", "isStravaAuthorized"])
  },
  methods: {
    ...mapMutations(["setLoading"]),
    async connect() {
      if (this.isTrainerDayUser && this.isStravaAuthorized !== undefined && !this.isStravaAuthorized) {
        this.error({ type: "connect-strava", message: "You didn't connect Strava" })
        return
      }

      const [err] = await to(this.$store.dispatch("pullStravaFacts"))

      if (err && err.response && err.response.status === 401) {
        this.error({ type: "connect-strava", message: "You didn't connect Strava" })
        return
      }

      if (err) {
        this.error({ type: "go-premium", message: "You don't have premium" })
        return
      }

      await this.$store.commit("setSkipStrava", false)
      await this.$router.push({ name: ROUTE_NAMES.WHATS_YOUR_NAME_DCS })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.logo-dcs {
  font-family: "Mont", black-italic, serif;
  color: white;
  font-size: 37.5px;
  margin-top: -7px;
}
.logo-td {
  padding-top: 25px;
  margin-left: 35px;
  width: 140px;
  height: 27px;
}
.cj-blue-round {
  width: 261px;
  height: 261px;
  margin-top: 35px;
  margin-left: -20px;
  background-color: $black;
  img {
    height: 375px;
  }
}
.robert-black-round {
  width: 261px;
  height: 261px;
  margin-top: 35px;
  img {
    transform: scale(1.2);
    margin-left: 20px;
    margin-top: 28px;
  }
}
.hello-text {
  width: 550px;
  margin: 30px auto auto;
  color: white;
}
.cj-says {
  color: white;
  font-family: "Poppins", semi-bold, serif;
}
.container {
  padding: $containerPadding;
  margin-top: 100px;
}
.cj-into-card {
  max-width: 1200px;
  background: $black;
  padding: 0;
}
.strava-button {
  background-color: #fc4c02;
  margin-top: 50px;
  color: white;
  border: 0;
  border-radius: 0;
  padding: 20px 50px 18px;
  height: auto;
}

.strava-button img {
  margin-left: 5px;
}
.cj-images {
  display: flex;
  justify-content: center;
}
</style>
