import { DEFAULT_DAILY_MINUTES } from "@/defaults"

const processRules = (block: any, name: string) => {
  if (name === "bestLongRideDays") {
    block.bestLongRideDays.forEach((d: string) => {
      if (!block.workoutDays.includes(d)) {
        block.workoutDays.push(d)
      }
    })
  }

  if (name === "workoutDays") {
    block.bestLongRideDays.forEach((d: string) => {
      if (!block.workoutDays.includes(d)) {
        const index = block.bestLongRideDays.indexOf(d)
        block.bestLongRideDays.splice(index, 1)
      }
    })
    if (block.minutesPerDay) {
      const updDays = Object.keys(block.minutesPerDay)
      updDays.forEach(day => {
        if (!block.workoutDays.includes(day)) {
          delete block.minutesPerDay[day]
        }
      })
      const newDays = block.workoutDays.filter((day: string) => !updDays.includes(day))
      block.minutesPerDay = {
        ...block.minutesPerDay,
        ...newDays.reduce((acc: { [x: string]: number }, cur: string | number) => {
          acc[cur] = DEFAULT_DAILY_MINUTES
          return acc
        }, {})
      }
    }
  }
}

export default processRules
