import { getPreferences, upsertPreferences } from "@/data"
import { AxiosError } from "axios"

const getDefaultPreferences = () => ({
  owner: "",
  isDailyEnabled: false,
  isWeeklyEnabled: false,
  dailyTime: "20:00",
  dailyTimeServer: "20:00",
  weeklyTime: "20:00",
  weeklyTimeServer: "20:00",
  weeklyDay: "sun",
  timeZone: "+00:00"
})
export type Preferences = {
  owner: string
  isDailyEnabled: boolean
  isWeeklyEnabled: boolean
  dailyTime: string
  dailyTimeServer: string
  weeklyTimeServer: string
  weeklyTime: string
  weeklyDay: string
  timeZone: string
  telegramChatId?: number
}
export default {
  namespaced: true,

  state: () => ({ preferences: getDefaultPreferences() }),
  getters: {
    owner: (state: { preferences: Preferences }) => state.preferences.owner,
    isConnected: (state: { preferences: Preferences }) => Boolean(state.preferences.telegramChatId),
    telegramChatId: (state: { preferences: Preferences }) => state.preferences.telegramChatId
  },
  mutations: {
    setPreferences(state: { preferences: Preferences }, preferences: any) {
      state.preferences = preferences
    },
    setNotConnected: (state: { preferences: Preferences }) => (state.preferences.telegramChatId = undefined)
  },
  actions: {
    async savePreferences({ rootState, commit }: any, preferences: any) {
      const { fromDCS } = preferences
      if (!fromDCS) {
        commit("setLoading", true, { root: true })
      }

      const { _id } = rootState
      preferences.owner = _id

      try {
        const response = await upsertPreferences(preferences)

        commit("setPreferences", response.data)
      } catch (e) {
        console.log(e)
      }
      if (!fromDCS) {
        commit("setLoading", false, { root: true })
      }
    },
    async getPreferences({ rootState, commit }: any) {
      const { _id } = rootState
      try {
        const response = await getPreferences(_id)
        commit("setPreferences", response.data)
      } catch (e) {
        const error = e as AxiosError
        console.log(error.name)
      }
    }
  }
}
