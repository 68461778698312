import { CustomPlan } from "@/store"
import { PLAN_TYPES, PlanType } from "@/types"
import { PlanValues } from "@/store/plan"

const createPlanName = (values: PlanValues, firstName: string, planType: PlanType | "", customPlan: CustomPlan): string => {
  if (!values.planTypeValues || !values.harderTypeValues) return ""

  const { planTypeValues, harderTypeValues } = values

  let planTitle
  if (planType === PLAN_TYPES.CUSTOM) {
    planTitle = `Custom plan: ${customPlan.name}`
  } else {
    planTitle =
      planTypeValues.find((type: any) => type.value === planType)?.name ||
      harderTypeValues.find((type: any) => type.value === planType)?.name ||
      ""
  }

  let planName

  if (!firstName) {
    planName = `${planTitle}`
  } else {
    if (planTitle === "The Serious Italian") {
      planTitle = planTitle.replace("The ", `${firstName}'s `)
      planName = `${planTitle}`
    } else {
      planName = `${firstName}'s ${planTitle}`
    }
  }

  if (planType !== PLAN_TYPES.CUSTOM) {
    return `${planName} plan`
  }

  return planName
}

export default createPlanName
