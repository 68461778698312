<template>
  <section class="container">
    <div class="cj-card cj-into-card text-center">
      <div class="cj-blue-round mb-16">
        <img src="@/assets/images/jack-full.svg" alt="Coach Jack" />
      </div>
      <div class="text-left">
        <h3 class="cj-says">Hi {{ profileFacts.firstName }}, nice to meet you!</h3>
        <p class="cj-desc mb-32">
          Enter your Functional Threshold Power (FTP)
        </p>
      </div>

      <div class="field text-left mb-24">
        <label class="label">Please provide a recent FTP *?</label>
        <div class="control">
          <input v-model="userFtp" class="input" type="text" ref="ftp" @keydown.enter="next" />
        </div>
        <div style="font-size:13px;color:#909090; margin-top:10px">
          * Coach Jack plans work great with a ramp test. If you have not taken one lately we suggest you do.
        </div>
      </div>
      <button @click="next" class="button-220 button is-red">
        Continue
      </button>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { ROUTE_NAMES } from "@/defaults"

export default {
  name: "StepFtp",
  data() {
    return {
      userFtp: 0
    }
  },
  computed: {
    ...mapState(["profileFacts", "externalFtp"])
  },
  beforeMount() {
    this.userFtp = this.profileFacts.ftp || this.externalFtp
  },
  mounted() {
    this.focusInput()
  },
  methods: {
    async next() {
      await this.$store.dispatch("upsertUser", { profileFacts: { ftp: this.userFtp } })
      await this.$router.push({ name: ROUTE_NAMES.QUESTIONING })
    },
    focusInput() {
      this.$refs.ftp.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.container {
  padding: $containerPadding;
}
.field {
  width: 100%;
}
</style>
