import { changeAndGetPlan, getValues, pushPlan, loadFromHistory } from "@/data"
import moment from "moment"
import {
  BLOCK_TYPES,
  BlockType,
  DaysOff,
  EventConfig,
  EventResult,
  PLAN_TYPES,
  PlanType,
  PushPlanParams
} from "@/types"
import { loadWorkoutsStats } from "@/store/services/loadWorkoutsStats"
import { Commit, Dispatch } from "vuex"
import { getEventDate } from "@/mixins/getEventDate"
import cloneDeep from "lodash.clonedeep"
import { AxiosError } from "axios"
import { CustomPlan, State } from "@/store/index"
import getStartDate from "@/services/getStartDate"
import { PLAN_CREATOR_MODE } from "@/defaults"

const splitDaysByWeeks = (workoutsByDays: any[]) =>
  workoutsByDays.reduce(
    (acc, curr, index) => {
      const isNextWeek = index !== 0 && (index / 7) % 1 === 0

      if (!isNextWeek) {
        acc[acc.length - 1].push(curr)
      } else {
        acc.push([curr])
      }

      return acc
    },
    [[]]
  )

const getWeekTotalValue = (week: any, valueName: string) => {
  return week.reduce(
    (acc: any[], day: any) =>
      acc +
      day.reduce((totalValue: number, workout: any) => {
        if (workout && valueName in workout) {
          return totalValue + parseInt(workout[valueName])
        }
        return totalValue
      }, 0),
    0
  )
}
interface Block {
  bestLongRideDays: string[]
  blockType: BlockType
  customPlan: CustomPlan
  customWorkoutTypes: []
  durationDays: number
  minWeeklyMinutes: number
  maxWeeklyMinutes: number
  planType: PlanType
  rampRate: number
  splitLongRides: boolean
  weeks: []
  weeksInMicroCycle: 4
  workoutDays: string[]
  workoutTypes: string[]
  currentIntervalIntensityLevel: number
  secondaryType: string
  secondaryIntensity: number
}
export type PlanConfig = {
  name: string
  startDate: string
  singleMultiBlockDurationWeeks: number
  isEventBasedPlan: boolean
  isSingleBlockOn: boolean
  events: EventConfig[]
  blocks: Block[]
  daysOff: DaysOff[]
  isDcs: boolean
  recoveryPattern: string
  calendarStartDate: string
  calendarColor: number
}

type PlanValue = {
  name: string
  value: string
}

type SecondaryIntensityValues = {
  name: string
  value: number
}

export type PlanTypeValues = {
  name: string
  value: string
  blockType: string
  description: string
}

export type PlanValues = {
  planTypeValues: PlanTypeValues[]
  harderTypeValues: PlanTypeValues[]
  peakTypeValues: PlanValue[]
  recoveryPatternValues: PlanValue[]
  workoutDaysValues: PlanValue[]
  secondaryIntensityValues: SecondaryIntensityValues[]
}

export type PlanState = {
  view: PlanView
  values?: PlanValues
  config?: PlanConfig
  result?: PlanResult
}

export type PlanResult = {
  workoutsByDays: []
  workoutsByDaysForPlans: []
  blocks: Block[]
  events: EventResult[]
}

type PlanView = {
  activeBlock: BlockType
  activeWeek: number
  workoutStatsLoading: boolean
}

type PlanUpdateValue = { name: keyof PlanConfig; value: PlanConfig[keyof PlanConfig] }
type BlockUpdateValue = { name: keyof Block; value: Block[keyof Block] }

const getEmptyState = (): PlanState =>
  cloneDeep({
    view: {
      activeBlock: null,
      activeWeek: 1,
      workoutStatsLoading: false
    },
    config: undefined,
    result: undefined
  })

export default {
  namespaced: true,

  state: (): PlanState => ({ ...getEmptyState(), values: undefined }),
  getters: {
    only4weeks: (state: PlanState, getters: any, rootState: State) => {
      const index = state.view.activeWeek - 1
      if (rootState.isMobile) {
        return [getters.weeksWithoutEmptyDays[index]]
      }
      return getters.weeksWithoutEmptyDays?.slice(index, index + 4)
    },
    getPlanInfo: (state: PlanState, getters: any) => {
      if (!state.values) {
        return null
      }

      const blockPlanType = getters.getBlockConfigValue("planType")
      if (blockPlanType === PLAN_TYPES.CUSTOM) {
        return getters.getBlockConfigValue("customPlan")
      }

      let planInfo = state.values.planTypeValues.find(type => type.value === blockPlanType)

      if (!planInfo) {
        planInfo = state.values.harderTypeValues.find(type => type.value === blockPlanType)
      }

      return planInfo
    },
    getActiveBlockConfig: (state: PlanState): null | Block => {
      // don't remove, it used
      if (!state.config?.blocks) {
        return null
      }
      return state.config.blocks.find((block: Block) => block.blockType === state.view.activeBlock) ?? null
    },
    getBlockConfigValue: (state: PlanState, getters: { getActiveBlockConfig: Block | null }) => (
      value: keyof Block
    ): null | Block[keyof Block] => (getters.getActiveBlockConfig ? getters.getActiveBlockConfig[value] : null),
    calculatedBlocks: (state: PlanState) => state.result?.blocks ?? [],
    workoutsByDays: (state: PlanState) => state.result?.workoutsByDays ?? [],
    firstPrimaryEvent: (state: PlanState) => state.config && state.config.events.find(e => e.primary),
    resultEvents: (state: PlanState): EventResult[] => state?.result?.events ?? [],
    isDcsPlan: (state: PlanState): boolean | undefined => {
      if (state.config) return state.config?.isDcs
    },
    resultFirstPrimaryEvent: (state: PlanState, getters: { resultEvents: EventResult[] }) =>
      getters.resultEvents.find(e => e.primary),
    getTotalWeeksFromDates: (state: PlanState, getters: any): number => {
      if (!state.config) {
        return 0
      }

      if (!state.config.isEventBasedPlan) {
        return state.config.singleMultiBlockDurationWeeks
      }

      if (!state.config || !getters.firstPrimaryEvent) {
        return 0
      }

      const { startDate } = state.config
      const { eventDate, weeksToEvent } = getters.firstPrimaryEvent
      return moment(eventDate ? eventDate : getEventDate(startDate, weeksToEvent))
        .add(1, "d")
        .diff(moment(startDate), "w")
    },

    getPlanConfigValue: (state: PlanState) => (name: keyof PlanConfig): PlanConfig[keyof PlanConfig] | null => {
      if (!state.config) {
        return null
      }

      return state.config[name]
    },
    getStrongestBlockPlanType: (state: PlanState): PlanType | "" => {
      if (!state.result) {
        return ""
      }
      const block = [...state.result.blocks].pop()

      return block ? block.planType : ""
    },
    blockTypes(state: PlanState) {
      if (!state.result) {
        return []
      }

      return state.result?.blocks.reduce((acc: any[], block: any) => {
        block.weeks.forEach(() => acc.push(block.blockType))
        return acc
      }, [])
    },
    weekDays(state: PlanState, getters: any) {
      const maxWeeks = 4
      const activeWeeks = getters.weeksWithoutEmptyDays.slice(
        state.view.activeWeek - 1,
        state.view.activeWeek - 1 + maxWeeks
      )
      const weeksDays = activeWeeks.map((week: any) => week.map((day: any) => !!day.length && day[0].type)).sort()

      return weeksDays.reduce(
        (acc: boolean[], week: any) => {
          week.map((day: any, index: number) => {
            if (day) {
              acc[index] = day
            }
          })
          return acc
        },
        [false, false, false, false, false, false, false]
      )
    },
    weeksWithoutEmptyDays(state: PlanState) {
      if (!state.result || state.result.workoutsByDays.length === 0) {
        return []
      }

      return splitDaysByWeeks(state.result.workoutsByDays)
    },
    chartValues(state: PlanState, getters: any) {
      const hoursPerWeeks = getters.weeksWithoutEmptyDays.map((week: any[]) =>
        Number((getWeekTotalValue(week, "durationMinutes") / 60).toFixed(1))
      )

      if (!hoursPerWeeks.length) return []

      const { activeWeek } = state.view
      const { blockTypes } = getters

      const availableWeeks = 4

      let weeks = hoursPerWeeks.map((hours: number, i: number) => {
        const weekNumber = i + 1
        const isActive = weekNumber >= activeWeek && weekNumber < availableWeeks + activeWeek

        return [hours, weekNumber, isActive]
      })
      const active = blockTypes[activeWeek] || blockTypes[activeWeek - 1]

      const cutStart = [...blockTypes].indexOf(active) <= 1 ? 0 : [...blockTypes].indexOf(active)
      const cutEnd = [...blockTypes].lastIndexOf(active) + 1

      weeks = weeks.slice(cutStart, cutEnd)
      return weeks
    },
    disabledBlocks(state: PlanState, getters: any) {
      const { blockTypes } = getters

      let lastOne: any = null

      return blockTypes.reduce((acc: any, current: string, index: number) => {
        const weekNumber = index + 1
        if (weekNumber > 1) return acc

        if (lastOne !== current && lastOne !== null) {
          acc.push(lastOne)
        }

        lastOne = current

        return acc
      }, [])
    },

    getPreviousBlock(state: PlanState): string | null {
      const { activeBlock } = state.view

      if (!state.config || !activeBlock) {
        return null
      }

      const blockTypes = state.config.blocks.map(block => block.blockType)

      const i = blockTypes.indexOf(activeBlock)
      if (i > 0) {
        return blockTypes[i - 1]
      }
      return null
    },

    getPlanTypesForBlock(state: PlanState): PlanTypeValues[] {
      if (!state.config || !state.values) {
        return []
      }

      const { planTypeValues } = state.values
      const { config } = state
      if (!config) return []
      const { isSingleBlockOn, isEventBasedPlan } = config
      const considerSingleBlockOn = !isEventBasedPlan && isSingleBlockOn

      if (considerSingleBlockOn && config.singleMultiBlockDurationWeeks > 8) {
        return planTypeValues.filter(type => type.blockType !== BLOCK_TYPES.PEAK)
      }
      if (!considerSingleBlockOn) {
        return planTypeValues.filter(type => type.blockType === state.view.activeBlock)
      } else {
        return planTypeValues
      }
    }
  },
  mutations: {
    emptyState(state: PlanState) {
      Object.assign(state, getEmptyState())
    },
    setPlan(state: PlanState, { result, config }: { result: PlanResult; config: PlanConfig }) {
      state.result = result
      state.config = config
    },
    setWorkoutsByDays(
      state: PlanState,
      { workoutsByDays, startDayIndex }: { workoutsByDays: []; startDayIndex: number }
    ) {
      if (!state?.result?.workoutsByDays) {
        return
      }

      state.result.workoutsByDays.splice(startDayIndex, 4 * 7, ...workoutsByDays)
    },
    setActiveBlock(state: PlanState, value: BlockType) {
      state.view.activeBlock = value
    },
    setActiveWeek(state: PlanState, activeWeek: number) {
      state.view.activeWeek = activeWeek
    },
    setWorkoutStatsLoading(state: PlanState, payload: boolean) {
      state.view.workoutStatsLoading = payload
    },
    setValues(state: PlanState, values: PlanValues) {
      state.values = values
    },
    setPlanConfigValue(state: PlanState, change: PlanUpdateValue | PlanUpdateValue[]) {
      if (!state.config) {
        return
      }

      const exists = (name: keyof PlanConfig) => state.config && name in state.config

      if (Array.isArray(change)) {
        change.forEach(({ name, value }) => {
          if (exists(name)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            state.config[name] = value
          }
        })
      } else {
        if (exists(change.name)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          state.config[change.name] = change.value
        }
      }
    },
    setActiveBlockConfigValue(state: PlanState, change: BlockUpdateValue | BlockUpdateValue[]) {
      if (!state.config) {
        return
      }

      const activeBlock = state.config.blocks.find(b => b.blockType === state.view.activeBlock)
      const exists = (name: keyof Block) => activeBlock && name in activeBlock

      if (Array.isArray(change)) {
        change.forEach(({ name, value }) => {
          if (!exists(name)) {
            return
          }

          if (exists(name)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            activeBlock[name] = value
          }
        })
      } else {
        if (exists(change.name)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          activeBlock[change.name] = change.value
          return
        }
      }
    }
  },
  actions: {
    updateActiveBlockConfigValue(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      change: PlanUpdateValue | PlanUpdateValue[]
    ) {
      commit("setActiveBlockConfigValue", change)

      void dispatch("changeAndGetPlan")
    },
    updatePlanConfigValue(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      change: PlanUpdateValue | PlanUpdateValue[]
    ) {
      commit("setPlanConfigValue", change)

      void dispatch("changeAndGetPlan")
    },

    async loadWorkoutsStats({ commit, state, getters }: { commit: Function; state: any; getters: any }) {
      commit("setWorkoutStatsLoading", true)

      const onAlreadyCalculated = () => {
        commit("setWorkoutStatsLoading", false)
      }

      const calculated = await loadWorkoutsStats(state.view.activeWeek, getters.workoutsByDays, onAlreadyCalculated)

      if (calculated) {
        const { workoutsByDays, startDayIndex, endDayIndex } = calculated

        commit("setWorkoutsByDays", { workoutsByDays, startDayIndex, endDayIndex })
        commit("setWorkoutStatsLoading", false)
      }
    },

    async changePlanType(
      { dispatch, state, getters }: { dispatch: Dispatch; state: PlanState; getters: any },
      value: PlanType
    ) {
      if (!state.values) {
        return
      }

      const prevValue = getters.getBlockConfigValue("planType")
      const { values } = state
      if (!values) return
      const previousValueEasier = values.planTypeValues.map(type => type.value).includes(prevValue)
      const newValueEasier = values.planTypeValues.map(type => type.value).includes(value)
      const shouldChangeIntensity = !previousValueEasier || !newValueEasier
      let customPlanType
      const updates = []

      if (values.planTypeValues.map(type => type.value).includes(value)) {
        if (shouldChangeIntensity) {
          const { secondaryIntensityValues } = values

          updates.push({ name: "currentIntervalIntensityLevel", value: 1 })
          updates.push({ name: "secondaryType", value: "default" })
          updates.push({ name: "secondaryIntensity", value: secondaryIntensityValues[0].value })

          customPlanType = ""
        }
      }
      if (values.harderTypeValues.map(type => type.value).includes(value) || value === PLAN_TYPES.CUSTOM) {
        customPlanType = PLAN_TYPES.CUSTOM
      }
      updates.push({ name: "planType", value })

      await dispatch("updateActiveBlockConfigValue", updates)

      return customPlanType
    },

    async getValues({ commit }: { commit: Commit }) {
      const response = await getValues()
      commit("setValues", response.data)
    },

    async loadFromHistory(_: any, historyId: string) {
      await loadFromHistory(historyId)
    },

    async changeAndGetPlan(
      {
        commit,
        dispatch,
        rootState,
        state
      }: {
        commit: Function
        dispatch: Function
        rootState: any
        state: PlanState
      },
      historyId: string
    ) {
      try {
        const params = {
          _id: rootState._id,
          ...(historyId && { historyId })
        }
        const initialResponse = await changeAndGetPlan({ ...params, config: state.config })

        const plan = initialResponse?.data
        commit("setPlan", plan)
        commit("setLoading", false, { root: true })

        dispatch("loadWorkoutsStats")

        return plan
      } catch (e) {
        console.log(e)
      } finally {
        commit("setLoading", false, { root: true })
      }
    },
    changeActiveWeek(
      { commit, dispatch, state, getters }: { commit: Function; dispatch: Function; state: PlanState; getters: any },
      weekNumber: number
    ) {
      commit("setActiveWeek", weekNumber)
      dispatch("loadWorkoutsStats")
      const blockType = getters.blockTypes[weekNumber - 1]
      if (state.view.activeBlock !== blockType) {
        commit("setActiveBlock", blockType)
      }
    },
    changeActiveBlock(
      { commit, dispatch, state, getters }: { commit: Function; dispatch: Function; state: PlanState; getters: any },
      blockType: string
    ) {
      const { blockTypes } = getters
      const { activeWeek } = state.view

      commit("setActiveBlock", blockType)

      if (blockTypes.indexOf(blockType) === -1) {
        // No such block type, seem like we switched to "any", so just set 1 week
        commit("setActiveWeek", 1)
        return
      }

      const blockStartWeek = blockTypes.indexOf(blockType) + 1
      const blockEndWeek = blockTypes.lastIndexOf(blockType) + 1

      const isWeekInRange = activeWeek >= blockStartWeek && activeWeek <= blockEndWeek
      if (!isWeekInRange) {
        commit("setActiveWeek", blockStartWeek)
        dispatch("loadWorkoutsStats")
      }
    },

    async pushPlan(
      { state, rootState, getters }: { state: PlanState; rootState: State; getters: any },
      { historyId, mode, destination, saveCopyToPlans, name, isDcs }: PushPlanParams
    ) {
      const { _id, token, profileFacts, userId } = rootState
      if (!state.result) {
        return
      }

      const calendarStartDate = getStartDate(getters.getPlanConfigValue("startDate"))

      const { workoutsByDays, workoutsByDaysForPlans } = state.result
      const timeout = 5 * 60_000

      const calendarColor = getters.getPlanConfigValue("calendarColor") || 1
      let data: any = {
        _id,
        token,
        mode,
        name,
        calendarStartDate,
        calendarColor,
        destination,
        userId,
        isDcs
      }

      if (mode === PLAN_CREATOR_MODE.CREATE || mode === PLAN_CREATOR_MODE.EDIT) {
        data = {
          ...data,
          historyId,
          planResult: state.result,
          workoutsByDays: destination === "calendar" ? workoutsByDays : workoutsByDaysForPlans,
          profileFacts,
          saveCopyToPlans
        }
      }

      try {
        const response = await pushPlan(data, timeout)
        return { planSlug: response?.data?.planSlug, historyId: response?.data?.historyId }
      } catch (e) {
        const error = e as AxiosError
        if (error.code === "ECONNABORTED") {
          alert("Something going wrong")
        }
      }
    }
  }
}
