
export default {
  name: "CoachProgress",
  props: {
    percent: {
      type: Number,
      default: 0
    }
  }
}
