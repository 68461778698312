import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store, { sentryUser } from "./store"
import SVGIcon from "@/components/SVGIcon.vue"
import BaseModal from "@/components/BaseModal.vue"
import BlueHeaderModal from "@/components/BlueHeaderModal.vue"
import VueSlider from "vue-slider-component"
import "vue-slider-component/theme/default.css"
import * as Sentry from "@sentry/vue"
import VueI18n from "vue-i18n"
import { messages, defaultLocale } from "@/i18n"
import configuration from "@/configuration"
import "@/filters"

import axios from "axios"
import localeInterceptor from "./data/locale-interceptor"
import responseInterceptor from "@/data/response-interceptor"
import { Locales } from "@/i18n/locales"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import localizedFormat from "dayjs/plugin/localizedFormat"
import relativeTime from "dayjs/plugin/relativeTime"

localeInterceptor()
responseInterceptor()

dayjs.extend(duration)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

Vue.use(VueI18n)

Vue.config.productionTip = false

Vue.component("svg-icon", SVGIcon)
Vue.component("BaseModal", BaseModal)
Vue.component("BlueHeaderModal", BlueHeaderModal)
Vue.component("VueSlider", VueSlider)

Sentry.init({
  Vue,
  dsn: configuration.sentryDsn,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "trainerday.com",
    "app.trainerday.com",
    "coachjack.trainerday.com",
    /^https:\/\/coachjack\.api\.trainerday\.com\/api/,
    /^\//
  ],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

const scope = Sentry.getCurrentScope()
scope.setUser(sentryUser)

Vue.prototype.$clicky = function(name: any, value: any) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (typeof clicky !== "undefined") {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    clicky.log(name, value)
  }
}

Vue.prototype.$trackEvent = function({
  name,
  value,
  payload
}: {
  name: string
  value?: string
  payload?: Record<string, unknown>
}) {
  if (process.env.APP_ENV === "production") {
    try {
      const { userId } = store.state
      void axios.post(configuration.eventsTrackerUrl, {
        userId,
        name,
        value,
        payload
      })
    } catch (e) {
      Sentry.captureException(e)
    }
  }
}

VueI18n.prototype.getChoiceIndex = function(choice) {
  const locale = this.locale || Locales.EN
  // Processing declensions for the Russian language
  if (locale === Locales.RU) {
    const lastDigit = choice % 10
    const lastTwoDigits = choice % 100

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return 2 // "недель"
    }
    if (lastDigit === 1) {
      return 0 // "неделя"
    }
    if (lastDigit >= 2 && lastDigit <= 4) {
      return 1 // "недели"
    }
    return 2 // "недель"
  }

  // Multiple form processing for English and other languages
  return choice === 1 ? 0 : 1
}

const i18n = new VueI18n({
  messages,
  locale: localStorage.getItem("lang") || defaultLocale,
  fallbackLocale: defaultLocale
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app")

export { i18n }
