import debounce from "lodash.debounce"

export default {
  data() {
    return {
      debouncedScaleFullWidth: () => ({})
    }
  },
  watch: {
    width(width) {
      this.svg.attr("width", width)
    }
  },
  mounted() {
    this.width = this.$parent.$el.clientWidth

    this.debouncedScaleFullWidth = debounce(() => {
      this.width = this.$parent.$el.clientWidth
    }, 100)

    window.addEventListener("resize", this.debouncedScaleFullWidth)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedScaleFullWidth)
  }
}
