<template>
  <div v-if="values && hasPrepared">
    <CoachImages v-if="isDcsAndNotEdit" class="flex-start mt-5 ml-5" />
    <PlanCreatorMainArea />
    <PlanFooter v-if="!isDcsAndNotEdit" :class="{ 'block-disabled': isBlockDisabled }" />
    <DcsPlanFooter v-else :class="{ 'block-disabled': isBlockDisabled }" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import PlanCreatorMainArea from "@/components/plan/MainArea/PlanCreatorMainArea.vue"
import CoachImages from "@/components/jack/CoachImages.vue"
import PlanFooter from "@/components/plan/Footer/PlanFooter.vue"
import { MIN_WEEKS_DURATION, ROUTE_NAMES } from "@/defaults"
import DcsPlanFooter from "@/components/dcs/DcsPlanFooter.vue"

export default {
  name: "PlanCreator",
  components: {
    CoachImages,
    PlanCreatorMainArea,
    PlanFooter,
    DcsPlanFooter
  },
  data() {
    return {
      hasPrepared: false
    }
  },
  computed: {
    ...mapState("plan", ["values", "config"]),
    ...mapState("assessment", ["questions", "isSkippingStrava"]),
    ...mapGetters("assessment", ["isAssessmentCompleted"]),
    ...mapGetters("plan", ["getTotalWeeksFromDates"]),
    ...mapGetters(["isQuestionsSkip", "isJackAssessmentCompletedOnceAtLeast", "isDcsOnboardingCompletedOnceAtLeast"]),
    isDcsAndNotEdit() {
      return this.config?.isDcs && !this.isEditMode
    },
    isEditMode() {
      return !!this.$route.query.historyId
    },
    isBlockDisabled() {
      return this.getTotalWeeksFromDates < MIN_WEEKS_DURATION
    }
  },
  methods: {
    ...mapActions("assessment", ["restoreQuestionsAndAnswers"])
  },
  async beforeMount() {
    this.hasPrepared = false
    this.$store.commit("plan/emptyState")

    const isDcs = this.$route.name === ROUTE_NAMES.PLAN_DCS
    const { historyId } = this.$route.query
    if (!historyId && this.isAssessmentCompleted) {
      if (this.isSkippingStrava) {
        await this.$store.dispatch("fillDefaultData", { isDcs })
      } else {
        await this.$store.dispatch("fillProfileFacts", { isDcs })
      }
    }

    await this.restoreQuestionsAndAnswers() // FIXME: find a better way to do it

    const isDcsUserFirstEnter = isDcs && !this.isDcsOnboardingCompletedOnceAtLeast

    if (!isDcsUserFirstEnter) {
      if ((!this.isJackAssessmentCompletedOnceAtLeast || !this.isAssessmentCompleted) && !historyId) {
        return this.$router.push("/jack")
      }
      if (!this.isQuestionsSkip && !historyId) {
        return this.$router.push({ name: ROUTE_NAMES.QUESTIONING })
      }

      if (historyId) {
        await this.$store.dispatch("plan/loadFromHistory", historyId)
      }
    }

    this.hasPrepared = true
  }
}
</script>
<style lang="scss" scoped>
.button-container {
  display: flex;
  justify-content: flex-end;
}
.plan-main {
  padding: 0;
}
</style>
