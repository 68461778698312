
import { Component, Vue } from "vue-property-decorator"
import Question from "../jack/Question.vue"
import CoachProgress from "@/views/jack/CoachProgress.vue"
import CoachImages from "@/components/jack/CoachImages.vue"
import { QuestionType } from "@/types"
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"
import { PLAN_CREATOR_MODE, ROUTE_NAMES } from "@/defaults"

@Component({
  name: "StepQuestioningDCS",
  components: { CoachProgress, Question, CoachImages },
  computed: {
    ...mapState("assessment", ["questions", "isSkippingStrava"]),
    ...mapGetters("assessment", ["isFinalQuestion", "completePercent", "getFinalQuestionText"]),
    ...mapGetters(["isDcsOnboardingCompletedOnceAtLeast", "getFirstName"]),
    isBlacked() {
      return this.$route.meta ? this.$route.meta.isBlacked : false
    }
  },
  methods: {
    ...mapMutations("assessment", ["editAnswer", "flushQuestions"]),
    ...mapActions("assessment", ["saveAnswer", "restoreQuestionsAndAnswers"])
  }
})
export default class Form3Questioning extends Vue {
  isScrolledTop = false
  questions!: QuestionType[]
  restoreQuestionsAndAnswers!: Function
  saveAnswer!: Function
  editAnswer!: Function
  flushQuestions!: Function
  getFirstName!: string
  isDcsOnboardingCompletedOnceAtLeast!: boolean

  handleScroll() {
    const hasVScroll = document.body.scrollHeight > document.body.clientHeight
    if (window.scrollY > 120 && hasVScroll) {
      this.isScrolledTop = true
    }
    if (window.scrollY <= 100 && hasVScroll) {
      this.isScrolledTop = false
    }
  }

  get welcomeText() {
    return this.isDcsOnboardingCompletedOnceAtLeast
      ? this.$t("stepQuestioning.niceToMeet")
      : this.$t("stepQuestioning.goodToSee")
  }

  async goNext() {
    await this.$router.push({ name: ROUTE_NAMES.PLAN_DCS, params: { mode: PLAN_CREATOR_MODE.CREATE } })
  }

  async saveAnswerAndScroll({ questionKey, textAnswer }: { questionKey: string; textAnswer: string }) {
    await this.saveAnswer({ questionKey, textAnswer })

    this.scrollToLastQuestion()
  }

  scrollToLastQuestion() {
    setTimeout(function() {
      window.scrollTo(0, document.body.scrollHeight)
    }, 20)
  }

  async beforeMount() {
    await this.restoreQuestionsAndAnswers()

    window.addEventListener("scroll", this.handleScroll)
    if (this.questions.length) {
      const lastQuestion = this.questions[this.questions.length - 1]

      await this.saveAnswerAndScroll({ questionKey: lastQuestion.key, textAnswer: "" })
    } else {
      // First Question
      await this.saveAnswerAndScroll({ questionKey: "", textAnswer: "" })
    }
  }

  clear() {
    this.flushQuestions()
    this.saveAnswerAndScroll({ questionKey: "", textAnswer: "" })
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  get isAnswered() {
    return this.questions.find(q => q.isAnswered)
  }
}
