import { getStudents } from "@/data"
import { State } from "@/store/index"

export type StudentsState = {
  studentList: StudentList
}
interface StudentList {
  name: string
  id: string
  userId: number
  hrvDeviation: number
}
export default {
  namespaced: true,

  state: () => ({
    studentList: []
  }),
  getters: {
    studentList: (state: StudentsState) => state.studentList
  },
  mutations: {
    setStudents(state: StudentsState, students: StudentList) {
      state.studentList = students
    }
  },
  actions: {
    async getStudents({ rootState, commit }: { rootState: State; commit: Function }) {
      commit("setLoading", true, { root: true })
      const { _id } = rootState

      if (!_id) {
        commit("setStudents", [])
        return
      }

      const response = await getStudents(_id)
      commit("setStudents", response.data)
      commit("setLoading", false, { root: true })
    }
  }
}
