<template>
  <section></section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { PLAN_CREATOR_MODE, ROUTE_NAMES } from "@/defaults"

export default {
  name: "Redirect",
  async beforeMount() {
    this.setLoading(true)

    if (this.getAction === "reset-user") {
      await this.resetProcess()
      this.setNotConnected()
    }

    await this.getPreferences()
    const isDcsPlanPage = location.href.includes("create-new-plan")

    if (!this.isConnected) {
      return this.$router.push({ name: ROUTE_NAMES.SIGNUP })
    }
    if (isDcsPlanPage && this.isConnected) {
      return this.$router.push({ name: ROUTE_NAMES.PLAN_DCS, params: { mode: PLAN_CREATOR_MODE.CREATE } })
    }
    if (!this.isDcsOnboardingCompletedOnceAtLeast) {
      return this.$router.push({ name: ROUTE_NAMES.WELCOME_DCS })
    }

    return this.$router.push({ name: ROUTE_NAMES.DASHBOARD })
  },
  computed: {
    ...mapGetters("preferences", ["isConnected"]),
    ...mapGetters(["getAction"]),
    ...mapGetters(["isDcsOnboardingCompletedOnceAtLeast"]),
    ...mapState(["profileFacts"])
  },
  methods: {
    ...mapMutations(["setLoading"]),
    ...mapMutations("preferences", ["setNotConnected"]),
    ...mapActions("preferences", ["getPreferences"]),
    ...mapActions(["resetProcess"])
  }
}
</script>
