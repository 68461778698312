export default function(zoneName) {
  const colors = {
    recovery: "#4DDD8A",
    endurance: "#61A7E8",
    tempo: "#8857E4",
    threshold: "#FFC562",
    vo2max: "#FE5959",
    anaerobic: "#000000"
  }
  const name = zoneName.replace(/\s/g, "").toLowerCase()
  return colors[name] || "#000000"
}
