import { ftpToWatts, getMinutesSeconds } from "@trainerday/cycling-converter"
import { select, event } from "d3-selection"
import { debounce } from "underscore"

export default {
  data() {
    return {
      hoverPolygons: null,
      messagesCursorEl: null,
      messagesCursorTextEl: null,
      messagesCursorPolyEl: null,
      tooltip: null,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      debouncedScaleHoverPoly: () => {}
    }
  },
  props: {
    showUpgradeMessage: {
      type: Boolean,
      default: false
    },
    ftp: {
      type: Number,
      default: 0
    },
    segmentsSelectedRange: {
      type: Array,
      default: () => []
    },
    messagesCursor: {
      type: Array,
      default: () => []
    },
    clickablePolygons: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    width() {
      this.$nextTick(() => {
        this.removeHoverPolygons()
        this.initHoverPolygons()
      })
    },
    segments() {
      this.$nextTick(() => {
        this.removeHoverPolygons()
        this.initHoverPolygons()
      })
    },
    segmentsSelectedRange([start, end]) {
      this.activatePolygons(start, end)
    },
    messagesCursor([time, powerPercent, offset]) {
      this.setCaret(time, powerPercent, offset)
    }
  },
  computed: {
    polyData() {
      let nextPoly = false
      let buffer = []

      const polyData = this.segmentsData.reduce((acc, current) => {
        buffer.push(current)

        if (nextPoly) {
          acc.push(buffer)
          buffer = []
          nextPoly = false
        } else {
          nextPoly = true
        }
        return acc
      }, [])

      return polyData.reduce((acc, [[timeStart, valueStart, cadenceStart, slope], [timeEnd, valueEnd, cadenceEnd]]) => {
        acc.push([
          [timeStart, valueStart, cadenceStart, slope],
          [timeEnd, valueEnd, cadenceEnd],
          [timeEnd, 0, null],
          [timeStart, 0, null]
        ])
        return acc
      }, [])
    }
  },
  methods: {
    setCaret(time, powerPercent, { minutes, seconds }) {
      const { x, y } = this

      const caretHalfWidth = 8

      if (time >= 0 && powerPercent) {
        this.messagesCursorEl &&
          this.messagesCursorEl
            .attr("transform", `translate(${x(time) - caretHalfWidth},${y(powerPercent + 20)})`)
            .style("visibility", "visible")
            .select("text")
            .text(`${minutes ? minutes + "m" : ""} ${seconds >= 0 ? seconds + "s" : ""}`)
      } else {
        this.messagesCursorEl && this.messagesCursorEl.style("visibility", "hidden")
      }
    },
    activatePolygons(start, end) {
      this.hoverPolygons &&
        this.hoverPolygons
          .selectAll("polygon")
          .style("opacity", 0)
          .style("fill", "rgba(255, 255, 255, 0.4)")

      if (start !== null && end !== null) {
        this.hoverPolygons &&
          this.hoverPolygons
            .selectAll("polygon")
            .filter(function(d, i) {
              return i >= start && i <= end
            })
            .style("fill", "red")
            .style("opacity", 1)
      }
    },

    tooltipTemplate([[timeStart, valueStart, cadenceStart, slope], [timeEnd, valueEnd, cadenceEnd]]) {
      const wattsStart = ftpToWatts(valueStart, this.ftp)
      const wattsEnd = ftpToWatts(valueEnd, this.ftp)

      let slopeRow = ""
      let cadenceRow = ""

      if (cadenceStart) {
        cadenceRow = `Cadence: <b>${cadenceStart}</b>${
          cadenceEnd && cadenceEnd !== cadenceStart ? "<b>-" + cadenceEnd + "</b>" : ""
        }`
      }

      if (slope !== null) {
        slopeRow = `Slope: <b>${slope} %</b>`
      }

      const wattsRow = `${this.$t("workoutChart.watts")}: <b>${wattsStart}</b>${
        wattsEnd !== wattsStart ? "<b>-" + wattsEnd + "</b>" : ""
      }`
      const percentRow = `${this.$t("workoutChart.percent")}: <b>${valueStart}</b>${
        valueEnd !== valueStart ? "<b>-" + valueEnd + "</b>" : ""
      }`
      const valuesText = this.ftp
        ? `${wattsRow}<br>${percentRow}`
        : `${this.$t("workoutChart.watts")}: <b>FTP Not Set</b><br>${percentRow}`
      const cadenceText = cadenceRow ? `${cadenceRow}<br>` : ""
      const slopeText = slopeRow ? `${slopeRow}<br>` : ""
      const startTimeText = `${this.$t("workoutChart.startTime")}: <b>${getMinutesSeconds(timeStart)} min</b>`
      const durationTimeText = `${this.$t("workoutChart.duration")}: <b>${getMinutesSeconds(
        timeEnd - timeStart
      )} min</b>`

      const upgradeMembershipText =
        "Watts: -<br>Percent: -<br>Start Time: -<br>Duration: -<br><b>Upgrade membership <br>to see actual values.</b>"

      return this.showUpgradeMessage
        ? upgradeMembershipText
        : `${valuesText}<br>${slopeText}${cadenceText}${startTimeText}<br>${durationTimeText}`
    },
    openTooltip(d) {
      const { tooltipTemplate, tooltip } = this

      const gap = 2
      const tooltipHeight = tooltip.node().getBoundingClientRect().height
      const tooltipWidth = tooltip.node().getBoundingClientRect().width
      const position = event.pageX + gap
      const windowWidth = window.innerWidth

      const leftPosition = position + tooltipWidth >= windowWidth ? position - tooltipWidth - gap * 2 : position

      tooltip
        .html(tooltipTemplate(d))
        .transition()
        .duration(0)
        .style("opacity", 0.9)
        .style("left", leftPosition + "px")
        .style("top", event.pageY - tooltipHeight - gap + "px")
    },
    removeHoverPolygons() {
      select(".messages-cursor").remove()
      select(".hover-polygons").remove()
      select(".workout-chart-tooltip").remove()
    },
    initHoverPolygons() {
      const { x, y } = this
      this.tooltip = select("body")
        .append("div")
        .attr("class", "workout-chart-tooltip")
        .style("opacity", 0)

      const { tooltip, openTooltip, getActiveSegments, onClick, clickablePolygons } = this

      this.hoverPolygons = this.svg.append("g").attr("class", "hover-polygons")

      this.messagesCursorEl = this.svg
        .append("g")
        .attr("class", "messages-cursor")
        .attr("transform", "translate(10, 10)")
        .attr("style", "visibility: hidden")

      this.messagesCursorTextEl = this.messagesCursorEl
        .append("text")
        .attr("class", "cursor-text")
        .attr("transform", "translate(-1,-3)")

      this.messagesCursorPolyEl = this.messagesCursorEl
        .append("text")
        .attr("class", "cursor-caret")
        .attr("points", "0,0 8,8 16,0")

      this.hoverPolygons
        .selectAll("polygon")
        .data(this.polyData)
        .enter()
        .append("polygon")
        .style("opacity", 0)
        .style("cursor", clickablePolygons ? "pointer" : "inherit")
        .style("fill", "rgba(255, 255, 255, 0.4)")
        .attr("points", d => d.map(([time, value]) => [x(time), y(value)].join(",")).join(" "))
        .on("mousemove", function(d) {
          select(this).style("opacity", 0.9)

          openTooltip(d)
        })
        .on("mouseover", function(d) {
          select(this).style("opacity", 0.9)

          openTooltip(d)
        })
        .on("mouseout", function(d, i) {
          const [start = -1, end = -1] = getActiveSegments()

          if (Number.isInteger(start) && Number.isInteger(end) && i >= start && i <= end) {
            select(this).style("opacity", 1)
          } else {
            select(this).style("opacity", 0)
          }

          tooltip
            .transition()
            .duration(100)
            .style("opacity", 0)
        })
        .on("click", function(d, i) {
          onClick(d, i)
        })

      if (!this.clickablePolygons) return

      const [start, end] = this.segmentsSelectedRange
      this.activatePolygons(start, end)

      const [time, powerPercent, offset] = this.messagesCursor
      this.setCaret(time, powerPercent, offset)
    },
    getActiveSegments() {
      return this.segmentsSelectedRange
    },
    onClick(d, segmentNumber) {
      this.$emit("segment-clicked", segmentNumber)
    }
  },
  mounted() {
    select(window).on("scroll", () => {
      if (this.tooltip) {
        this.tooltip.style("opacity", 0)
      }
    })

    this.$nextTick(() => {
      this.initHoverPolygons()
    })

    this.debouncedScaleHoverPoly = debounce(() => {
      this.removeHoverPolygons()
      this.initHoverPolygons()
    }, 100)

    window.addEventListener("resize", this.debouncedScaleHoverPoly)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedScaleHoverPoly)
    this.removeHoverPolygons()
  }
}
