
import { Component, Vue } from "vue-property-decorator"
import { mapState } from "vuex"
import { PLAN_CREATOR_MODE, ROUTE_NAMES } from "@/defaults"

@Component({
  name: "StepSummary",
  computed: {
    ...mapState(["profileFacts", "values"])
  }
})
export default class StepSummary extends Vue {
  private values!: any
  private profileFacts!: any

  stravaStats = [
    { name: "Age", value: "54" },
    { name: "System", value: "kg + cm" },
    { name: "Weight", value: "93 kg" },
    { name: "Height", value: "188 cm" }
  ]

  get workoutDays() {
    return (
      this.profileFacts.workoutDays &&
      this.profileFacts.workoutDays.map((d: string) => d.charAt(0).toUpperCase() + d.slice(1)).join(", ")
    )
  }

  get planTypeName() {
    const { planTypeValues } = this.values

    if (planTypeValues) {
      return planTypeValues.find((v: any) => v.value === this.profileFacts.planType).name
    }

    return ""
  }

  next() {
    this.$router.push({ name: ROUTE_NAMES.PLAN, params: { mode: PLAN_CREATOR_MODE.CREATE } })
  }
}
