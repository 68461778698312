import { calcWorkoutStats } from "@/data"

const loadWorkoutsStats = async (activeWeek: number, workoutsByDays: any[], onAlreadyCalculated: () => void) => {
  const activeWeekIndex = activeWeek - 1
  const startDayIndex = activeWeekIndex * 7
  const fourWeeksInDays = 7 * 4
  const endDayIndex = startDayIndex + fourWeeksInDays
  const workoutsByDaysForCurrentRange = workoutsByDays.slice(startDayIndex, endDayIndex)

  const firstNonEmptyDay = workoutsByDaysForCurrentRange.find((day: []) => day.length)
  const [anyWorkout] = firstNonEmptyDay

  if (anyWorkout.tss > 0) {
    // We already calculated stats
    onAlreadyCalculated()
    return
  }

  const extendedResponse = await calcWorkoutStats(workoutsByDaysForCurrentRange)

  return { workoutsByDays: extendedResponse?.data, startDayIndex, endDayIndex }
}

export { loadWorkoutsStats }
