<template>
  <section class="container coach-jack">
    <div class="cj-card cj-into-card text-center">
      <div class="cj-blue-round mb-16">
        <img src="@/assets/images/jack-full.svg" alt="Coach Jack" />
      </div>
      <div class="has-text-centered">
        <h3 class="cj-says ">{{ $t("stepWelcome.hello") }}</h3>
        <div class="cj-desc-small mb-32">
          {{ $t("stepWelcome.strava") }}
        </div>
      </div>

      <button @click="connect" class="button strava-button">
        {{ isStravaAuthorized ? $t("stepWelcome.analyze") : $t("stepWelcome.connect") }}
        <img src="@/assets/images/strava-logo.svg" alt="Strava Logo" />
      </button>
      <div class="mt-6" style="margin-bottom:2px">{{ $t("stepWelcome.continue") }}</div>
      <b
        ><a @click.prevent="skipStrava" style="font-weight:400" class="is-underline mb-6" href="#">{{
          $t("stepWelcome.skip")
        }}</a></b
      >
      <!--      <div class="mt-6"><b><a @click.prevent="skip" class="is-underline" href="#">Skip Strava</a></b> and Coach Jacks more detailed evaluation,</div>-->
      <!--      but you will need to answer a few extra questions to help him pick the best plan for you.-->

      <!--    <a class="link-button mb-5 mt-5" href="#">I don’t use Strava</a>-->
      <!--    <a class="link-button mb-5" href="#">I don’t have a power meter</a>-->
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex"
import to from "await-to-js"
import error from "@/mixins/error"
import { ANALYZE_STRAVA, CONNECT_STRAVA, SKIP_STRAVA } from "@/services/eventNames"
import { ROUTE_NAMES } from "@/defaults"

export default {
  name: "StepWelcome",
  mixins: [error],
  computed: {
    ...mapGetters(["isTrainerDayUser", "isStravaAuthorized"])
  },
  methods: {
    async connect() {
      if (this.isTrainerDayUser && this.isStravaAuthorized !== undefined && !this.isStravaAuthorized) {
        this.error({ type: "connect-strava", message: "You didn't connect Strava" })
        return
      }

      const [err] = await to(this.$store.dispatch("pullStravaFacts"))

      if (err && err.response && err.response.status === 401) {
        this.error({ type: "connect-strava", message: "You didn't connect Strava" })
        return
      }

      if (err) {
        this.error({ type: "go-premium", message: "You don't have premium" })
        return
      }

      await this.$store.commit("setSkipStrava", false)
      await this.$trackEvent({ name: this.isStravaAuthorized ? ANALYZE_STRAVA : CONNECT_STRAVA })
      await this.$router.push({ name: ROUTE_NAMES.WHATS_YOUR_NAME })
    },
    async skipStrava() {
      try {
        await this.$store.dispatch("checkPremium")
      } catch (err) {
        const { status } = err.response

        if (status === 403) {
          this.error({ type: "go-premium", message: "You don't have premium" })
        }

        if (status === 500) {
          this.error({ message: err.response.data })
        }
        return
      }

      await this.$store.commit("setSkipStrava", true)
      await this.$trackEvent({ name: SKIP_STRAVA })
      await this.$router.push({ name: ROUTE_NAMES.WHATS_YOUR_NAME })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.container {
  padding: $containerPadding;
}
.strava-button {
  background-color: #fc4c02;
  color: white;
  border: 0;
  border-radius: 0;
  padding: 20px 50px 18px;
  height: auto;
}

.strava-button img {
  margin-left: 5px;
}
</style>
