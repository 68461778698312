// Function for dynamic locale loading
import { Locales } from "@/i18n/locales"
import dayjs from "dayjs"

export const setDayjsLocale = async (locale: string) => {
  try {
    switch (locale) {
      case Locales.EN:
        await import("dayjs/locale/en")
        break
      case Locales.ES:
        await import("dayjs/locale/es")
        break
      case Locales.RU:
        await import("dayjs/locale/ru")
        break
      case Locales.DE:
        await import("dayjs/locale/de")
        break
      case Locales.IT:
        await import("dayjs/locale/it")
        break
      case Locales.FR:
        await import("dayjs/locale/fr")
        break
      case Locales.PT:
        await import("dayjs/locale/pt")
        break
      case Locales.ZH_CN:
        await import("dayjs/locale/zh-cn")
        break
      case Locales.ZH_TW:
        await import("dayjs/locale/zh-tw")
        break
      case Locales.PL:
        await import("dayjs/locale/pl")
        break
      case Locales.JA:
        await import("dayjs/locale/ja")
        break
      default:
        await import("dayjs/locale/en")
        break
    }
    dayjs.locale(locale)
  } catch (error) {
    console.error(`Error loading locale ${locale}:`, error)
    dayjs.locale(Locales.EN)
  }
}
