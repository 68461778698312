<template>
  <section class="container">
    <p class="cj-says">Connect your telegram and you will receive all upcoming instructions there.</p>
    <br />
    <TelegramConnect />
  </section>
</template>

<script>
import TelegramConnect from "@/components/preferences/TelegramConnect.vue"
import { mapActions, mapMutations } from "vuex"

export default {
  name: "SignUp",
  components: { TelegramConnect },
  async mounted() {
    await this.upsertProfileByToken()
    this.setLoading(false)
  },
  methods: {
    ...mapActions(["upsertProfileByToken"]),
    ...mapMutations(["setLoading"])
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.cj-says {
  color: white;
  font-family: "Poppins", semi-bold, serif;
}
.container {
  margin-top: 100px;
  padding: $containerPadding $containerPadding $containerPadding 20px;
}
</style>
