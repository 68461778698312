
import { Component, Prop, Vue } from "vue-property-decorator"
import { Answer, QuestionType } from "@/types"

@Component
export default class Question extends Vue {
  @Prop() private question!: QuestionType
  @Prop() private isBlacked!: boolean
  private values: string[] = []

  get userChoices() {
    const { userChoice } = this.question

    if (Array.isArray(userChoice)) {
      return userChoice.join(", ").replace(/-/g, " ")
    }

    return userChoice
  }

  get UserChoiceText() {
    let { userChoice }: any = this.question
    userChoice = this.question.answers.find(a => a.value === userChoice)?.text
    return userChoice
  }

  beforeMount() {
    const { userChoice } = this.question

    if (Array.isArray(userChoice)) {
      this.values = userChoice
    }
  }

  loadNextQuestionOrSave(textAnswer: string) {
    const { key: questionKey } = this.question

    if (this.question.type !== "checkbox") {
      this.$emit("saveAnswer", { questionKey, textAnswer })
      return
    }
    let count = 0
    this.values = []
    this.question.answers.forEach((answer: Answer) => {
      answer.class = ""
      if ((answer.value === textAnswer && !answer.userChoice) || (answer.value !== textAnswer && answer.userChoice)) {
        answer.class = "boxRed"
        count++
        this.values.push(answer.value)
      }
    })
    if (count == this.question.limit) {
      this.$emit("saveAnswer", { questionKey, textAnswer: this.values })
    }
  }
}
